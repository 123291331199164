import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Image from "gatsby-image"

// Styles
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"

// Components
import SEO from "../components/Seo"
import BackButton from "../components/atoms/BackButton"
import ContentWrapper from "../components/ContentWrapper"
import Richtext from "../components/atoms/Richtext"
import ContactBlock from "../components/ContactBlock"

// Types
import { IAboutpage } from "../types/pages/about"

const AboutPage = ({ metaData, about, footer }: IAboutpage) => (
  <>
    <SEO title={metaData.title} description={metaData.description} />
    <Container>
      <BackButton inverted />
      {about.image.url && (
        <ContentWrapper size="small">
          <StyledImage fluid={about.image.url} alt={about.image.alt} />
        </ContentWrapper>
      )}
      <ContentWrapper size="small">
        <Title>{about.title}</Title>
        <StyledRichtext html={about.text} />
        <StyledContactBlock {...footer} inverted />
      </ContentWrapper>
    </Container>
  </>
)

const Container = styled.div`
  position: relative;
  z-index: -1;
  padding-top: 96px;
  background-color: ${colors.caribbean};

  ${mq.from.M`
    padding-top: 120px;
  `}
`

const StyledImage = styled(Image)`
  margin-bottom: 40px;
  max-width: 400px;
`

const Title = styled.h1<any>`
  ${textStyles.pageTitle};
  margin-bottom: 24px;

  ${mq.from.M`
    margin-bottom: 32px;
  `}
`

const StyledRichtext = styled(Richtext)`
  margin-bottom: 56px;

  ${mq.from.M`
    margin-bottom: 72px;
  `}
`

const StyledContactBlock = styled(ContactBlock)`
  padding-bottom: 56px;

  ${mq.from.M`
    padding-bottom: 72px;
  `}
`

export const query = graphql`
  query aboutPage {
    prismicAboutPage {
      data {
        image {
          alt
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        title {
          text
        }
        text {
          html
        }
        footer_title {
          text
        }
      }
    }
    prismicContact {
      data {
        email_address
        phone_label
        phone_number
        linkedin_label
        linkedin_url
      }
    }
  }
`

export default ({ data }: any) => {
  const rawData = {
    page: {
      ...data.prismicAboutPage.data,
    },
    contact: {
      ...data.prismicContact.data,
    },
  }

  const metaData = {
    title: rawData.page.meta_title,
    description: rawData.page.meta_description,
  }

  const about = {
    title: rawData.page.title.text,
    text: rawData.page.text.html,
    image: {
      url: rawData.page.image.fluid,
      alt: rawData.page.image.alt,
    },
  }

  const footer = {
    title: rawData.page.footer_title.text,
    email: rawData.contact.email_address,
    phone: {
      label: rawData.contact.phone_label,
      number: rawData.contact.phone_number,
    },
    linkedin: {
      label: rawData.contact.linkedin_label,
      url: rawData.contact.linkedin_url,
    },
  }

  return <AboutPage metaData={metaData} about={about} footer={footer} />
}
